import "views/LegalNotices/LegalNotices.css";
import React, { useEffect, useRef, useState } from "react";
import ImgNextGen from "components/ImgNextGen";
import { ReactComponent as SVGIconsCode } from "assets/svg/icons.code.svg";
import { ReactComponent as SVGIconsHeart } from "assets/svg/icons.heart.svg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export const LegalNotices = (props: any) => {
  const topPosition = useRef<HTMLDivElement>(null);
  const forWho = useRef<HTMLDivElement>(null);
  const about = useRef<HTMLDivElement>(null);
  const contact = useRef<HTMLDivElement>(null);
  const forWhoSectorsRef = useRef<HTMLDivElement>(null);
  const clientsLogosRef = useRef<HTMLDivElement>(null);

  const [flagHovered, setFlagHovered] = useState(false);
  const [showFlagLanguages, setShowFlagLanguages] = useState(false);

  // For who sections animation useEffect
  useEffect(() => {
    const currentRef: HTMLDivElement | null = forWhoSectorsRef.current;
    const elementToObserve: Element | null =
      document.querySelector(".for-who-sectors");

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const elements = Array.from(
          entries[0].target.getElementsByTagName("div")
        );

        elements.forEach((child, index) => {
          if (index % 2 === 0) {
            if (child.classList.contains("left"))
              child.classList.add("animate", "slide-left");
            else child.classList.add("animate", "slide-right");

            child.style.animationDelay = `${index / 2 / 4}s`;
          }
        });
      }
    });

    if (elementToObserve) observer.observe(elementToObserve);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [forWhoSectorsRef]);

  // Clients logos animation useEffect
  useEffect(() => {
    const currentRef = clientsLogosRef.current;
    const elementToObserve: Element | null = document.querySelector(
      ".clients-logos-container"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const elements = Array.from(
          entries[0].target.getElementsByTagName("div")
        );

        elements.forEach((child, index) => {
          child.classList.add("animate", "pop", `delay-${index}`);
        });
      }
    });

    if (elementToObserve) observer.observe(elementToObserve);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [clientsLogosRef]);

  const handleMouseOverFlag = () => {
    setFlagHovered((prevState) => true);
    setShowFlagLanguages((prevState) => true);
  };

  const handleMouseLeaveFlag = () => {
    setFlagHovered((prevState) => false);
    setShowFlagLanguages((prevState) => false);
  };

  const handleOnClickFRLanguage = () => {
    props.changeLanguage("fr");
  };

  const handleOnClickNLLanguage = () => {
    props.changeLanguage("nl");
  };

  const handleOnClickENLanguage = () => {
    props.changeLanguage("en");
  };

  return (
    <div ref={topPosition} className="container">
      <nav className="navbar">
        <div className="navbar-logo-container">
          <Link to="/">
            <img
              src="assets/img/logo.svg"
              alt="company-logo"
              width={37}
              height={47}
            />
          </Link>
        </div>

        <div className="navbar-flag-container">
          <div
            className="flag-container"
            onMouseOver={handleMouseOverFlag}
            onMouseLeave={handleMouseLeaveFlag}
          >
            <div className={`flag ${flagHovered ? "hovered" : ""}`} />
            <div
              className={`flag-languages ${
                showFlagLanguages ? "displayed" : "invisible"
              }`}
            >
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "fr" ? "selected" : ""}`}
                onClick={handleOnClickFRLanguage}
              >
                FR
              </span>
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "nl" ? "selected" : ""}`}
                onClick={handleOnClickNLLanguage}
              >
                NL
              </span>
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "en" ? "selected" : ""}`}
                onClick={handleOnClickENLanguage}
              >
                EN
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="legal-notices-container">
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.legal_notices.title"} />
          </h1>
          <p>
            <FormattedMessage id={"lang.legal_notices.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text2"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text3"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text5"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text6"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text7"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text8"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text9"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text10"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text11"} />
          </p>
        </div>
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.privacy_policy.title"} />
          </h1>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle.text2"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle2.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle2.text"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text2"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text3"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text4"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text5"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text6"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle4.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle4.text"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text2"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text3"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle2"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text4"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle3"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text5"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle4"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text6"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle5"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text7"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text8"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text9"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text10"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text11"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text12"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle6"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text13"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle7"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text14"} />
          </p>
        </div>
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.terms.title"} />
          </h1>
          <p>
            <FormattedMessage id={"lang.terms.text"} />
          </p>

          <ol>
            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle2.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle2.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle3.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle3.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle4.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle4.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle5.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle5.text"} />
              </p>
              <p>
                <FormattedMessage id={"lang.terms.subtitle5.text2"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle6.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle6.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle7.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle7.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle8.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle8.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle9.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle9.text"} />
              </p>
              <p>
                <FormattedMessage id={"lang.terms.subtitle9.text2"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle10.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle10.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle11.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle11.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle12.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle12.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle13.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle13.text"} />
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div className="footer-bottom-legal-notices">
        <Link to={"/"} className="legal-notices">
          <p>
            <FormattedMessage id={"lang.home"} />
          </p>
        </Link>
        <a
          href="https://www.mds-digitalagency.be"
          rel="noopener, noreferrer"
          className="copyright-container"
          target="_blank"
        >
          <div className="copyright">
            <SVGIconsCode height={17} className="copyright-code" />
            {"by "}
            <span>MDS Digital Agency </span>
            {" with"}
            <SVGIconsHeart height={17} className="copyright-heart" />
          </div>
          <div className="copyright-colored">
            <SVGIconsCode height={17} className="copyright-code" />
            {"by "}
            <span>MDS Digital Agency </span>
            {" with"}
            <SVGIconsHeart height={17} className="copyright-heart" />
          </div>
        </a>
      </div>
    </div>
  );
};
