import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import "App.css";
import { Home, LegalNotices, Error404 } from "views/";
import frFRMessages from "locales/fr-FR.json";
import nlNLMessages from "locales/nl-NL.json";
import enUSMessages from "locales/en-US.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const LANGUAGES: any = {
  fr: frFRMessages,
  nl: nlNLMessages,
  en: enUSMessages,
};

const App = () => {
  const [locale, setLocale] = useState<string>("en");

  const changeLanguage = (locale: string) => {
    setLocale(locale);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <IntlProvider
        locale={locale}
        defaultLocale={locale}
        messages={LANGUAGES[locale]}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ""}
          language={locale ?? "en"}
        >
          <Routes>
            <Route path="/">
              <Route
                path="/"
                element={
                  <Home
                    changeLanguage={changeLanguage}
                    currentLocale={locale}
                  />
                }
              />
            </Route>
            <Route
              path="/legal-notices"
              element={
                <LegalNotices
                  changeLanguage={changeLanguage}
                  currentLocale={locale}
                />
              }
            />
          </Routes>
        </GoogleReCaptchaProvider>
      </IntlProvider>
    </BrowserRouter>
  );
};

export default App;
