import "views/Home/Home.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ImgNextGen from "components/ImgNextGen";
import { ReactComponent as SVGIconsCode } from "assets/svg/icons.code.svg";
import { ReactComponent as SVGIconsHeart } from "assets/svg/icons.heart.svg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ContactForm from "helpers/ContactForm";

export const Home = (props: any) => {
  const topPosition = useRef<HTMLDivElement>(null);
  const forWho = useRef<HTMLDivElement>(null);
  const about = useRef<HTMLDivElement>(null);
  const contact = useRef<HTMLDivElement>(null);
  const forWhoSectorsRef = useRef<HTMLDivElement>(null);
  const clientsLogosRef = useRef<HTMLDivElement>(null);

  const [flagHovered, setFlagHovered] = useState(false);
  const [showFlagLanguages, setShowFlagLanguages] = useState(false);

  const [isMessageSent, setIsMessageSent] = useState(false);

  // For who sections animation useEffect
  useEffect(() => {
    const currentRef: HTMLDivElement | null = forWhoSectorsRef.current;
    const elementToObserve: Element | null =
      document.querySelector(".for-who-sectors");

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const elements = Array.from(
            entries[0].target.getElementsByTagName("div")
          );

          elements.forEach((child, index) => {
            if (index % 2 === 0) {
              if (child.classList.contains("left"))
                child.classList.add("animate", "slide-left");
              else child.classList.add("animate", "slide-right");

              child.style.animationDelay = `${index / 2 / 4}s`;
            }
          });
        }
      },
      { rootMargin: "0px 0px -50% 0px" }
    );

    if (elementToObserve) observer.observe(elementToObserve);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [forWhoSectorsRef]);

  // Clients logos animation useEffect
  useEffect(() => {
    const currentRef = clientsLogosRef.current;
    const elementToObserve: Element | null = document.querySelector(
      ".clients-logos-container"
    );

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const elements = Array.from(
          entries[0].target.getElementsByTagName("div")
        );

        elements.forEach((child, index) => {
          child.classList.add("animate", "pop", `delay-${index}`);
        });
      }
    });

    if (elementToObserve) observer.observe(elementToObserve);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [clientsLogosRef]);

  const scrollToTop = () => {
    if (topPosition.current) {
      topPosition.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToForWhoSection = () => {
    if (forWho.current) {
      forWho.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToAboutSection = () => {
    if (about.current) {
      about.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToContactSection = () => {
    if (contact.current) {
      contact.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleMouseOverFlag = () => {
    setFlagHovered(() => true);
    setShowFlagLanguages(() => true);
  };

  const handleMouseLeaveFlag = () => {
    setFlagHovered(() => false);
    setShowFlagLanguages(() => false);
  };

  const handleOnClickFRLanguage = () => {
    props.changeLanguage("fr");
  };

  const handleOnClickNLLanguage = () => {
    props.changeLanguage("nl");
  };

  const handleOnClickENLanguage = () => {
    props.changeLanguage("en");
  };

  const messageHasBeenSent = () => {
    setIsMessageSent(true);
  };

  return (
    <div ref={topPosition} className="container">
      <nav className="navbar-fixed">
        <div className="navbar-fixed-container">
          <h5 onClick={scrollToAboutSection}>
            <FormattedMessage id={"lang.navbar.about"} />
          </h5>
          <h5 onClick={scrollToForWhoSection}>
            <FormattedMessage id={"lang.navbar.for_who"} />
          </h5>
          <h5 onClick={scrollToContactSection}>
            <FormattedMessage id={"lang.navbar.contact"} />
          </h5>
        </div>
      </nav>
      <nav className="navbar">
        <div className="navbar-logo-container">
          <img
            src="assets/img/logo.svg"
            alt="company-logo"
            width={37}
            height={47}
            onClick={scrollToTop}
          />
        </div>

        <div className="navbar-flag-container">
          <div
            className="flag-container"
            onMouseOver={handleMouseOverFlag}
            onMouseLeave={handleMouseLeaveFlag}
          >
            <div className={`flag ${flagHovered ? "hovered" : ""}`} />
            <div
              className={`flag-languages ${
                showFlagLanguages ? "displayed" : "invisible"
              }`}
            >
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "fr" ? "selected" : ""}`}
                onClick={handleOnClickFRLanguage}
              >
                FR
              </span>
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "nl" ? "selected" : ""}`}
                onClick={handleOnClickNLLanguage}
              >
                NL
              </span>
              <span
                className={`flag-language ${
                  showFlagLanguages ? "displayed" : "invisible"
                } ${props.currentLocale === "en" ? "selected" : ""}`}
                onClick={handleOnClickENLanguage}
              >
                EN
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="background-books-container">
        <div className="background-books">
          <div className="quote-container">
            <p className="quote-text">
              <FormattedMessage
                id={"lang.quote.text"}
                values={{ br: <br /> }}
              />
            </p>
            <p className="quote-author">
              <FormattedMessage id={"lang.quote.author"} />
            </p>
          </div>
        </div>
      </div>
      <div className="clients-logos-best-translation-container">
        <div className="clients-logos-container" ref={clientsLogosRef}>
          <div>
            <img src="assets/svg/Salesforce.svg" alt="Salesforce" />
          </div>
          <div>
            <img src="assets/svg/Apple.svg" alt="Apple" />
          </div>
          <div>
            <img src="assets/svg/Lego.svg" alt="Lego" />
          </div>
          <div>
            <img src="assets/svg/Dell.svg" alt="Dell" />
          </div>
          <div>
            <img src="assets/svg/Google.svg" alt="Google" />
          </div>
          <div>
            <img src="assets/svg/Acronis.svg" alt="Acronis" />
          </div>
          <div>
            <img src="assets/svg/Pinterest.svg" alt="Pinterest" />
          </div>
          <div>
            <img src="assets/svg/Henkel.svg" alt="Henkel" />
          </div>
          <div>
            <img src="assets/svg/Iherb.svg" alt="iHerb" />
          </div>
          <div>
            <img src="assets/svg/Medtronic.svg" alt="Medtronic" />
          </div>
        </div>
        <div className="best-translation-possible-container">
          <div ref={about} className="scroll-to-anchor" />
          <h1 className="best-translation-possible-title">
            <FormattedMessage id={"lang.best_translation_possible.title"} />
          </h1>

          <p>
            <FormattedMessage id={"lang.best_translation_possible.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.best_translation_possible.text2"} />
          </p>
          <p>
            <FormattedMessage id={"lang.best_translation_possible.text3"} />
          </p>
        </div>
      </div>
      <div className="background-holding-books">
        <div>
          <p className="join-us-text">
            <FormattedMessage id={"lang.join_us.text"} />
          </p>
          <h1 className="stop-waiting-text">
            <FormattedMessage id={"lang.join_us.title"} />
          </h1>
          <div className="button-container">
            <div className="button" onClick={scrollToContactSection}>
              <FormattedMessage id={"lang.join_us.cta"} />
            </div>
          </div>
        </div>
      </div>
      <div className="for-who position-relative">
        <div ref={forWho} className="scroll-to-anchor" style={{ top: -50 }} />
        <h1 className="for-who-title">
          <FormattedMessage id={"lang.for_who.title"} />
        </h1>
      </div>
      <div className="for-who-sectors" ref={forWhoSectorsRef}>
        <div className="sector-company left">
          <ImgNextGen
            srcWebp={"assets/webp/company.webp"}
            fallback={"assets/img/company.jpg"}
            alt={"Société"}
          />
          <div className="sector-text-container">
            <hr />
            <h2>
              <FormattedMessage id={"lang.for_who.company.title"} />
            </h2>
            <p>
              <FormattedMessage id={"lang.for_who.company.text"} />
            </p>
          </div>
        </div>

        <div className="sector-press-agency right">
          <ImgNextGen
            srcWebp={"assets/webp/press-agency.webp"}
            fallback={"assets/img/press-agency.jpg"}
            alt={"Agence de presse"}
          />
          <div className="sector-text-container">
            <hr />
            <h2>
              <FormattedMessage id={"lang.for_who.press_agency.title"} />
            </h2>
            <p>
              <FormattedMessage id={"lang.for_who.press_agency.text"} />
            </p>
          </div>
        </div>

        <div className="sector-organization left">
          <ImgNextGen
            srcWebp={"assets/webp/organization.webp"}
            fallback={"assets/img/organization.jpg"}
            alt={"Institution & organisation"}
          />
          <div className="sector-text-container">
            <hr />
            <h2>
              <FormattedMessage id={"lang.for_who.organization.title"} />
            </h2>
            <p>
              <FormattedMessage id={"lang.for_who.organization.text"} />
            </p>
          </div>
        </div>
        <div className="sector-legal right">
          <ImgNextGen
            srcWebp={"assets/webp/legal.webp"}
            fallback={"assets/img/legal.jpg"}
            alt={"Secteur juridique"}
          />
          <div className="sector-text-container">
            <hr />
            <h2>
              <FormattedMessage id={"lang.for_who.legal.title"} />
            </h2>
            <p>
              <FormattedMessage id={"lang.for_who.legal.text"} />
            </p>
          </div>
        </div>

        <div className="sector-medical left">
          <ImgNextGen
            srcWebp={"assets/webp/medical.webp"}
            fallback={"assets/img/medical.jpg"}
            alt={"Secteur médical"}
          />
          <div className="sector-text-container">
            <hr />
            <h2>
              <FormattedMessage id={"lang.for_who.medical.title"} />
            </h2>
            <p>
              <FormattedMessage id={"lang.for_who.medical.text"} />
            </p>
          </div>
        </div>
      </div>
      <div className="footer position-relative">
        <div ref={contact} className="scroll-to-anchor" style={{ top: 30 }} />
        <h1 className="stop-waiting-text">
          <FormattedMessage id={"lang.stop_waiting.form.title"} />
        </h1>
        <div className="footer-container">
          <div style={{ position: "relative" }}>
            <ContactForm
              messageHasBeenSent={messageHasBeenSent}
              blurred={isMessageSent ? "blurred" : ""}
              currentLocale={props.currentLocale}
            />
            {isMessageSent && (
              <div className="from-sent-container">
                <p className="from-sent-text">
                  <FormattedMessage id="lang.stop_waiting.form.success" />
                </p>
              </div>
            )}
          </div>

          <div className="contact-us-right-section">
            <div className="company-infos">
              <p className="company-infos-title">
                <FormattedMessage id={"lang.company_infos.title"} />
              </p>
              <p className="company-number">
                <FormattedMessage id={"lang.company_infos.company_number"} />
              </p>
              <div className="company-infos-text">
                <div>
                  <span>
                    <FormattedMessage
                      id={"lang.company_infos.address.street"}
                    />
                  </span>
                  <span>
                    <FormattedMessage id={"lang.company_infos.address.city"} />
                  </span>
                  <span>
                    <FormattedMessage
                      id={"lang.company_infos.address.country"}
                    />
                  </span>
                </div>
                <div>
                  <a href="tel:+33785902713" className="company-infos-phone">
                    <FormattedMessage id={"lang.company_infos.phone"} />
                  </a>
                  <a href="tel:+32475657496" className="company-infos-phone">
                    <FormattedMessage id={"lang.company_infos.phone2"} />
                  </a>
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }} className="freelance-wrapper">
              <img
                src="assets/img/freelance.jpg"
                alt="Jeune entrepreneur"
                className="freelance-img"
              />
              <div>
                <div className="hr" />
                <div className="freelance-text-wrapper">
                  <h2 className="freelance-title">
                    <FormattedMessage id={"lang.freelance.title"} />
                  </h2>
                  <p className="freelance-text">
                    <FormattedMessage id={"lang.freelance.text"} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <Link to={"/legal-notices"} className="legal-notices">
          <p>
            <FormattedMessage id={"lang.legal_notices"} />
          </p>
        </Link>
        <a
          href="https://www.mds-digitalagency.be"
          rel="noopener, noreferrer"
          className="copyright-container"
          target="_blank"
        >
          <div className="copyright">
            <SVGIconsCode height={17} className="copyright-code" />
            {"by "}
            <span>MDS Digital Agency </span>
            {" with"}
            <SVGIconsHeart height={17} className="copyright-heart" />
          </div>
          <div className="copyright-colored">
            <SVGIconsCode height={17} className="copyright-code" />
            {"by "}
            <span>MDS Digital Agency </span>
            {" with"}
            <SVGIconsHeart height={17} className="copyright-heart" />
          </div>
        </a>
      </div>
    </div>
  );
};
