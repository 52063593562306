import React from "react";

type ImgNextGenProps = {
  srcWebp: string;
  fallback: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  alt: string;
};

const ImgNextGen = ({
  srcWebp,
  fallback,
  alt,
  className,
  style,
  width,
  height,
}: ImgNextGenProps) => {
  return (
    <picture>
      <source srcSet={srcWebp} type="image/webp" />
      <img
        src={fallback}
        alt={alt}
        className={className}
        style={style}
        width={width}
        height={height}
      />
    </picture>
  );
};

export default ImgNextGen;
