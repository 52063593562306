import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

const FORM_SUBMIT_API_URL =
  "https://ehhckpqinh.execute-api.eu-west-1.amazonaws.com/prod/contact";

const ContactForm = ({ messageHasBeenSent, blurred, currentLocale }: any) => {
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Formik
      initialValues={{
        surname: "",
        name: "",
        company: "",
        email: "",
        message: "",
      }}
      validationSchema={Yup.object({
        surname: Yup.string().required(
          intl.formatMessage({ id: "lang.stop_waiting.form.required" })
        ),
        name: Yup.string().required(
          intl.formatMessage({ id: "lang.stop_waiting.form.required" })
        ),
        company: Yup.string().required(
          intl.formatMessage({ id: "lang.stop_waiting.form.required" })
        ),
        email: Yup.string()
          .email(
            intl.formatMessage({ id: "lang.stop_waiting.form.invalid_email" })
          )
          .required(
            intl.formatMessage({ id: "lang.stop_waiting.form.required" })
          ),
        message: Yup.string()
          .min(
            10,
            intl.formatMessage({ id: "lang.stop_waiting.form.minimum.10" })
          )
          .required(
            intl.formatMessage({ id: "lang.stop_waiting.form.required" })
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setIsSubmitting(true);

        executeRecaptcha?.("contact/post")
          .then((recaptchaToken) => {
            const data = { ...values, language: currentLocale, recaptchaToken };
            axios
              .post(FORM_SUBMIT_API_URL, JSON.stringify(data))
              .then((res) => {
                // console.log("res:", res);

                messageHasBeenSent();
                setSubmitting(false);
                setIsSubmitting(false);
                return;
              })
              .catch((err) => {
                // console.log("error:", err);
                setIsSubmitting(false);
              });
          })
          .catch((err) => {
            setSubmitting(false);
            setIsSubmitting(false);
            // console.log("error:", err);
          });
      }}
    >
      <Form className={`contact-us-left-section ${blurred}`}>
        <FormattedMessage
          id={"lang.stop_waiting.form.field.surname.placeholder"}
        >
          {(msg) => (
            <>
              <Field
                name="surname"
                type="text"
                placeholder={msg.toString()}
                required
              />
              <span className="error-text">
                <ErrorMessage name="surname" />
              </span>
            </>
          )}
        </FormattedMessage>

        <FormattedMessage id={"lang.stop_waiting.form.field.name.placeholder"}>
          {(msg) => (
            <>
              <Field
                name="name"
                type="text"
                placeholder={msg.toString()}
                required
              />
              <span className="error-text">
                <ErrorMessage name="name" />
              </span>
            </>
          )}
        </FormattedMessage>

        <FormattedMessage
          id={"lang.stop_waiting.form.field.company.placeholder"}
        >
          {(msg) => (
            <>
              <Field
                name="company"
                type="text"
                placeholder={msg.toString()}
                required
              />
              <span className="error-text">
                <ErrorMessage name="company" />
              </span>
            </>
          )}
        </FormattedMessage>

        <FormattedMessage id={"lang.stop_waiting.form.field.email.placeholder"}>
          {(msg) => (
            <>
              <Field
                name="email"
                type="text"
                placeholder={msg.toString()}
                required
              />
              <span className="error-text">
                <ErrorMessage name="email" />
              </span>
            </>
          )}
        </FormattedMessage>

        <FormattedMessage
          id={"lang.stop_waiting.form.field.message.placeholder"}
        >
          {(msg) => (
            <>
              <Field
                name="message"
                type="textarea"
                as="textarea"
                placeholder={msg.toString()}
                required
              />
              <span className="error-text">
                <ErrorMessage name="message" />
              </span>
            </>
          )}
        </FormattedMessage>
        <div className="button-container">
          <div>
            <button
              type="submit"
              className={`button ${isSubmitting ? "button-disabled" : ""}`}
              disabled={isSubmitting}
            >
              <FormattedMessage
                id={
                  isSubmitting
                    ? "lang.stop_waiting.form.submitting"
                    : "lang.stop_waiting.form.submit"
                }
              />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactForm;
